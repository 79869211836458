<template>
  <section id="form-wizard-ads">
    <b-row class="mb-5">
      <b-col md="10">
        <h1>{{ $t('adsDisplay.ads') }}</h1>
        <h5 class="text-primary">
          {{ $t('adsDisplay.adsAdd') }}
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end mr-1">
        <div>
          <b-button
            variant="danger"
            @click="routeToEditView()"
          >
            {{ $t("dataGeneric.goBack") }}
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-overlay
      variant="transparent"
      :show="show"
    >
      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        :finish-button-text="$t('dataGeneric.create')"
        :back-button-text="$t('dataGeneric.goBack')"
        :next-button-text="$t('next')"
        class="mb-3"
        @on-complete="formSubmitted"
      >
        <tab-content
          :title="$t('code.info')"
          :before-change="validationFormInfo"
        >
          <validation-observer
            ref="infoRules"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ $t("code.info") }}
                </h5>
                <small class="text-muted">
                  {{ $t("code.introd") }}
                </small>
              </b-col>

              <b-col md="6">
                <validation-provider
                  v-slot="validationContext"
                  name="name"
                  rules="required|min:3"
                >
                  <b-form-group :label="$t('code.introd') + ' *'">
                    <b-form-input
                      id="name"
                      v-model="name"
                      autocomplete="new-password"
                      :state="validationContext.errors.length > 0 ? false : null"
                      :placeholder="$t('firstName')"
                    />
                    <small class="text-danger">{{ fix(validationContext) }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('dataGeneric.description')">
                  <b-form-textarea
                    v-model="description"
                    :placeholder="$t('dataGeneric.description')"
                    rows="3"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <tab-content
          :title="$t('Configuration')"
          :before-change="validationFormConfig"
        >
          <validation-observer
            ref="ConfigRules"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ $t("code.adsConf") }}
                </h5>
                <small class="text-muted">
                  {{ $t("code.conf") }}
                </small>
              </b-col>

              <b-col md="6">
                <validation-provider
                  v-slot="validationContext"
                  name="adUnitId"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('adsDisplay.adsUnitId') + ' *'"
                    :invalid-feedback="$t('required')"
                    :state="validationContext.errors.length > 0 ? false : null"
                  >
                    <b-form-input
                      id="adUnitId"
                      v-model="adUnitId"
                      autocomplete="new-password"
                      :state="validationContext.errors.length > 0 ? false : null"
                      :placeholder="$t('adsDisplay.adsUnitId')"
                    />
                    <small class="text-primary">{{ $t("adsDisplay.adsUnitId-description") }}</small> <br>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('adsDisplay.adsLocation')">
                  <validation-provider
                    v-slot="validationContext"
                    name="location"
                    rules="required"
                  >
                    <b-form-select
                      v-model="location"
                      :disabled="true"
                      :options="locationOptions"
                    />
                    <small class="text-primary">{{ $t("adsDisplay.adsLocation-description") }}</small><br>
                    <small class="text-danger">{{ inputDecimalNumber(validationContext) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('adsDisplay.adsPosition')">
                  <validation-provider
                    v-slot="{ errors }"
                    name="positionCategory"
                    rules="required"
                  >
                    <b-form-select
                      v-model="positionCategory"
                      :options="positionCategoryOptions"
                    />
                    <small class="text-primary">
                      {{ $t("adsDisplay.adsPosition-description") }}</small><br>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="5">
                <validation-provider
                  v-slot="{ errors }"
                  name="rootCategoryName"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('adsDisplay.category')"
                    :invalid-feedback="$t('reCat')"
                    :state="errors.length > 0 ? false : null"
                  >
                    <div class="d-flex">
                      <b-form-input
                        id="rootCategoryName"
                        v-model="rootCategoryName"
                        disabled
                        :placeholder="$t('adsDisplay.category-description')"
                        :state="errors.length > 0 ? false : null"
                      />
                      <b-button
                        class="ml-2"
                        variant="primary"
                        @click.stop.prevent="showModal('cat')"
                      >
                        {{ $t('Select') }}
                      </b-button>
                    </div>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- MODAL PARA SELECCIONAR CATEGORÍA -->
              <b-col>
                <b-modal
                  ref="CatModal"
                  size="xl"
                  hide-footer
                  :title="$t('modal.category')"
                >
                  <div class="d-block text-center">
                    <category-selector
                      :root-categories="true"
                      :columns="[
                        { key: 'image', label: $t('contents.category') },
                        { key: 'name', label: $t('firstName') },
                        { key: 'type', label: $t('type') },
                      ]"
                      @confirm-selection="getCategory"
                    />
                  </div>
                  <b-button
                    class="mt-3"
                    variant="outline-danger"
                    block
                    @click.stop.prevent="hideModal('cat')"
                  >
                    {{ $t("dataGeneric.close") }}
                  </b-button>
                </b-modal>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
      </form-wizard>
    </b-overlay>
  </section>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  showToast, messageError, inputDecimalNumber,
} from '@/store/functions'

import {
  BFormTextarea,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BOverlay,
  BFormSelect,
} from 'bootstrap-vue'
import { required } from '@validations'
import axios from '@axios'

import { getUserData } from '@/auth/utils'
import CategorySelector from '@/views/common/CategorySelector.vue'

export default {
  components: {
    BButton,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BFormTextarea,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BOverlay,
    BFormSelect,
    CategorySelector,
  },
  data() {
    return {
      userData: getUserData(),
      inputDecimalNumber,
      required,
      prints: null,
      timeout: null,
      description: '',
      name: null,
      show: false,
      locationOptions: [
        { value: 'TOP', text: this.$t('top') },
        { value: 'MID', text: this.$t('middle') },
        { value: 'BOT', text: this.$t('bottom') },
      ],
      location: 'MID',
      positionCategoryOptions: [
        { value: 'PRE', text: this.$t('adsDisplay.positionPre') },
        { value: 'POS', text: this.$t('adsDisplay.positionPos') },
      ],
      positionCategory: 'PRE',
      adUnitId: null,
      first: true,

      checkResults: [],
      checkRemove: [],
      requiredField: false,
      rootCategory: null,
      rootCategoryName: '',

    }
  },
  watch: {
    timeout() {
      if (this.timeout.match(/\d+$/) === null) this.timeout = ''
    },
    prints() {
      if (this.prints.match(/^\d+$/) === null) this.prints = ''
    },
  },
  mounted() {
    this.getRootCategories()
  },
  methods: {
    fix(data) {
      const existeClave = Object.keys(data.failedRules)
      if (existeClave.includes('required')) {
        return this.$t('required')
      }
      if (existeClave.includes('min')) {
        return this.$t('purchases.nameFormThreeCaract')
      }
      return ''
    },
    requierdFunction(data) {
      if (data.length !== 0) {
        return this.$t('required')
      }
      return ''
    },

    formSubmitted() {
      this.show = true
      const isActive = this.rootCategory.isActive && this.rootCategory.state === 'GRE'
      axios
        .post('', {
          query: `
          mutation{
            createDisplayAds(input:{
              name:"${this.name}",
              client:"${this.userData.profile.client.id}",
              description:"${this.description}",
              campaign:"${this.$route.params.campaign}",
              isActive:${isActive},
              positionCategory: ${this.positionCategory},
              adUnitId: "${this.adUnitId}",
              location: ${this.location},
              category: ${this.rootCategory.id},

              }){
            displayAd
            {
              name
            }
          }
          }
        `,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.creado'), 1, this)
          this.show = false

          this.routeToEditView()
        })
        .catch(error => {
          console.log(error)

          showToast(this.$t('code.errorCre'), 2, this)
          this.show = false
        })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormConfig() {
      return new Promise((resolve, reject) => {
        this.$refs.ConfigRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    getIdArray(array) {
      const res = []
      for (let i = 0; i < array.length; i += 1) {
        res.push(array[i].node.id)
      }
      return res
    },
    routeToEditView() {
      const { campaign } = this.$route.params
      this.$router.push({
        name: 'display-ads-list',
        params: { campaign },
      })
    },
    saveRange(node = null) {
      this.positionCategory = node
    },
    getCategory(item) {
      this.rootCategory = item.node
      this.rootCategoryName = this.rootCategory.name
      this.hideModal('cat')
    },
    showModal(type) {
      switch (type) {
        case 'cat':
          this.$refs.CatModal.show()
          break
        default:

          break
      }
    },
    hideModal(type) {
      switch (type) {
        case 'cat':
          this.$refs.CatModal.hide()
          break
        default:
          break
      }
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";

#form-wizard-ads .head {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
}

#form-wizard-ads .scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 200px;
}

#form-wizard-ads .modal {
  overflow-y: auto;
}

/*
Full screen Modal
*/
#form-wizard-ads .fullscreen-modal .modal-dialog {
  margin: 0;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

@media (min-width: 768px) {
  #form-wizard-ads .fullscreen-modal .modal-dialog {
    width: 750px;
  }
}

@media (min-width: 992px) {
  #form-wizard-ads .fullscreen-modal .modal-dialog {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  #form-wizard-ads .fullscreen-modal .modal-dialog {
    width: 1170px;
  }

  .range-slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 10px;
  }

  .range-input {
    width: 100%;
    margin: 20px 0;
    -webkit-appearance: none;
    background: transparent;
  }

  .range-input::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    animate: 0.2s;
    background: #7367f0;
    border-radius: 5px;
  }

  .range-input::-webkit-slider-thumb {
    border: 1px solid #000000;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5px;
  }

  .range-input:focus::-webkit-slider-runnable-track {
    background: #7367f0;
  }

  .range-labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: -10px;
  }
}
</style>
